<template>
  <b-container fluid>
    <b-overlay
      :show="showLoading"
      :opacity="1"
      variant="light"
      rounded="sm"
    >
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <strong>Carregando Análise, Aguarde...</strong>
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
          <b-spinner
            type="grow"
            variant="primary"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
        </div>
      </template>
      <b-embed
        :src="iframeUrl"
        type="iframe"
        aspect="16by9"
        allowfullscreen
      />
    </b-overlay>
  </b-container>
</template>
<script>
import {
  BContainer,
  BEmbed,
  BSpinner,
  BOverlay,
  BIcon,
} from 'bootstrap-vue'
import metaBase from '@/service/reports/reportsMetaBase'

export default {
  components: {
    BContainer,
    BEmbed,
    BSpinner,
    BOverlay,
    BIcon,
  },
  data() {
    return {
      iframeUrl: metaBase(85, true),
      showLoading: false,
    }
  },
}
</script>
